import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Template from '../templates/IndexPage';

const query = graphql`
  query {
    pageYaml(page: { eq: "index" }) {
      hero
      siteHeader {
        title
        description
      }
      features {
        title
        description
        items {
          header
          body
        }
      }
    }
  }
`;

const IndexPage = () => (
  <StaticQuery query={query} render={(data) => <Template pageData={data.pageYaml} />} />
);

export default IndexPage;
