import React from 'react';
import styled from '@emotion/styled';

import PhotoBarItem from '../atoms/PhotoBarItem';

const PhotoBar = () => (
  <Root>
    {[...Array(4).keys()].map((number) => (
      <Item key={number.toString()}>
        <PhotoBarItem number={number.toString()} />
      </Item>
    ))}
  </Root>
);

const Root = styled.div({
  display: 'flex',
});

const Item = styled.div((props) => ({
  width: props.theme.layout.maxWidth / 4,
}));

export default PhotoBar;
