// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import TextBlock from '../atoms/TextBlock';

type Props = {
  title: String,
  description: String,
};

const PageHeader = ({ title, description }: Props) => (
  <Root>
    <Title>{title}</Title>
    <TextBlock align="center" text={description} />
  </Root>
);

const Root = styled.div((props) => ({
  padding: '1rem 1rem',
  [props.theme.mediaQuery.minTablet]: {
    padding: '2rem 1rem',
  },
}));

const Title = styled.div((props) => ({
  color: props.theme.colors.primary,
  fontSize: '1.5rem',
  textAlign: 'center',
  [props.theme.mediaQuery.minTablet]: {
    fontSize: '2rem',
  },
}));

export default PageHeader;
