// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

type Props = {
  number?: '0' | '1' | '2' | '3',
};

const query = graphql`
  query {
    item1: file(relativePath: { eq: "PhotoBar/1.png" }) {
      childImageSharp {
        fluid(maxWidth: 265) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    item2: file(relativePath: { eq: "PhotoBar/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 265) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    item3: file(relativePath: { eq: "PhotoBar/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 265) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    item4: file(relativePath: { eq: "PhotoBar/4.png" }) {
      childImageSharp {
        fluid(maxWidth: 265) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const PhotoBarItem = ({ number }: Props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      let fluid = '';
      const alt = `photo-bar-item${number}`;
      switch (number) {
        case '0':
          fluid = data.item1.childImageSharp.fluid;
          break;
        case '1':
          fluid = data.item2.childImageSharp.fluid;
          break;
        case '2':
          fluid = data.item3.childImageSharp.fluid;
          break;
        case '3':
          fluid = data.item4.childImageSharp.fluid;
          break;
        default:
          fluid = '';
      }
      return <Image fluid={fluid} alt={alt} />;
    }}
  />
);

PhotoBarItem.defaultProps = {
  number: '0',
};

const Image = styled(Img)({
  maxWidth: 265,
});

export default PhotoBarItem;
