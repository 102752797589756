// @flow strict
import React from 'react';

import Hero from '../atoms/Hero';
import SiteHeader from '../organisms/SiteHeader';
import PhotoBar from '../organisms/PhotoBar';
import Features from '../organisms/Features';

type Props = {
  pageData: {
    hero: String,
    siteHeader: {
      title: String,
      description: String,
    },
    features: {
      title: String,
      description: String,
    },
  },
};

const IndexPage = ({ pageData }: Props) => (
  <div>
    <Hero color={pageData.hero} />
    <SiteHeader
      title={pageData.siteHeader.title}
      description={pageData.siteHeader.description}
    />
    <PhotoBar />
    <Features data={pageData.features} />
  </div>
);

export default IndexPage;
