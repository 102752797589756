// @flow strict
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';

import Section from '../molecules/Section';
import SubSectionHeader from '../molecules/SubSectionHeader';
import TextBlock from '../atoms/TextBlock';

type Props = {
  data: {
    title: String,
    description: String,
    items: Array,
  },
};

const query = graphql`
  query {
    file(relativePath: { eq: "feature-bg.jpg" }) {
      publicURL
    }
  }
`;

const Features = ({ data }: Props) => {
  const bgData = useStaticQuery(query);
  const { title, description, items } = data;
  const theme = useTheme();
  const width = useSelector((state) => state.dimension.width);
  const align = width < theme.breakpoints.smartphone ? 'left' : 'center';

  return (
    <Section title={title}>
      <TextBlock align={align} text={description} />
      <DescriptionList bgUrl={bgData.file.publicURL}>
        {items.map((item, index) => {
          return (
            <div key={index.toString()}>
              <SubSectionHeader text={item.header} />
              <TextBlock align={align} text={item.body} />
            </div>
          );
        })}
      </DescriptionList>
    </Section>
  );
};

const DescriptionList = styled.div((props) => ({
  backgroundImage: `url(${props.bgUrl})`,
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  padding: '2rem 1rem',
}));

export default Features;
